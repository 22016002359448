@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #eff6ff;
}
.input-bg {
  padding: 0px 0px 0px 5px;
  background: transparent;
}
#Table {
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
  table-layout: auto;
}

#Table thead tr {
  background: transparent;
}

#Table tbody tr:last-child {
  border-bottom: 0;
}

#Table th,
#Table td {
  padding: 0.7em;
  text-align: start;
  /* font-size: 15px; */
}

#Table th {
  font-size: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-bottom: #001039 solid 1px;
}
#Table th:last-child {
  text-align: center;
  width: 10%;
}

@media screen and (max-width: 767px) {
  #Table {
    border: 0;
  }
  #Table th:nth-child(2),
  #Table td:nth-child(2) {
    text-align: end;
  }

  #Table th,
  #Table td {
    width: 100% !important;
  }

  #Table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  #Table tr {
    border-bottom: 3px solid #001039;
    display: block;
    margin-bottom: 0.625em;
  }

  #Table tr:last-child {
    border: none;
  }

  #Table td {
    border-bottom: 1px solid #001039;
    display: block;
    font-size: 1em;
    text-align: right;
    padding: 1.5em 12px;
  }

  #Table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  #Table td:last-child {
    border-bottom: 0;
  }
}

/* fadein */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
